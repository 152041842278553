// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-battery-change-reminder-tsx": () => import("./../../../src/pages/battery-change-reminder.tsx" /* webpackChunkName: "component---src-pages-battery-change-reminder-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-keys-replacement-payment-tsx": () => import("./../../../src/pages/keys-replacement-payment.tsx" /* webpackChunkName: "component---src-pages-keys-replacement-payment-tsx" */),
  "component---src-pages-keys-replacement-tsx": () => import("./../../../src/pages/keys-replacement.tsx" /* webpackChunkName: "component---src-pages-keys-replacement-tsx" */),
  "component---src-pages-lost-assistance-application-tsx": () => import("./../../../src/pages/lost-assistance-application.tsx" /* webpackChunkName: "component---src-pages-lost-assistance-application-tsx" */),
  "component---src-pages-lost-combination-assistance-tsx": () => import("./../../../src/pages/lost-combination-assistance.tsx" /* webpackChunkName: "component---src-pages-lost-combination-assistance-tsx" */),
  "component---src-pages-lost-combination-payment-tsx": () => import("./../../../src/pages/lost-combination-payment.tsx" /* webpackChunkName: "component---src-pages-lost-combination-payment-tsx" */),
  "component---src-pages-our-legacy-tsx": () => import("./../../../src/pages/our-legacy.tsx" /* webpackChunkName: "component---src-pages-our-legacy-tsx" */),
  "component---src-pages-register-your-safe-tsx": () => import("./../../../src/pages/register-your-safe.tsx" /* webpackChunkName: "component---src-pages-register-your-safe-tsx" */),
  "component---src-pages-replacement-parts-payment-tsx": () => import("./../../../src/pages/replacement-parts-payment.tsx" /* webpackChunkName: "component---src-pages-replacement-parts-payment-tsx" */),
  "component---src-pages-replacement-parts-tsx": () => import("./../../../src/pages/replacement-parts.tsx" /* webpackChunkName: "component---src-pages-replacement-parts-tsx" */),
  "component---src-pages-safes-tsx": () => import("./../../../src/pages/safes.tsx" /* webpackChunkName: "component---src-pages-safes-tsx" */),
  "component---src-pages-user-manuals-by-lock-type-tsx": () => import("./../../../src/pages/user-manuals-by-lock-type.tsx" /* webpackChunkName: "component---src-pages-user-manuals-by-lock-type-tsx" */),
  "component---src-pages-user-manuals-tsx": () => import("./../../../src/pages/user-manuals.tsx" /* webpackChunkName: "component---src-pages-user-manuals-tsx" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */),
  "component---src-pages-where-to-buy-tsx": () => import("./../../../src/pages/where-to-buy.tsx" /* webpackChunkName: "component---src-pages-where-to-buy-tsx" */),
  "component---src-templates-blog-article-tsx": () => import("./../../../src/templates/blog-article.tsx" /* webpackChunkName: "component---src-templates-blog-article-tsx" */),
  "component---src-templates-documents-page-tsx": () => import("./../../../src/templates/documentsPage.tsx" /* webpackChunkName: "component---src-templates-documents-page-tsx" */),
  "component---src-templates-manual-by-lock-tsx": () => import("./../../../src/templates/manualByLock.tsx" /* webpackChunkName: "component---src-templates-manual-by-lock-tsx" */),
  "component---src-templates-manual-tsx": () => import("./../../../src/templates/manual.tsx" /* webpackChunkName: "component---src-templates-manual-tsx" */),
  "component---src-templates-product-lines-tsx": () => import("./../../../src/templates/product-lines.tsx" /* webpackChunkName: "component---src-templates-product-lines-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-safes-series-tsx": () => import("./../../../src/templates/safesSeries.tsx" /* webpackChunkName: "component---src-templates-safes-series-tsx" */)
}

